import { Injectable } from '@angular/core';
import { OpinionDTO } from 'common';

@Injectable({
  providedIn: 'root',
})
export class OpinionDataService {
  opinionFODTO: OpinionDTO = null;
  constructor() {}
}
